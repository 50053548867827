<template>
  <div style="margin: 0 auto; max-width: 500px;padding: 10px;min-width: 500px;">
    <h3 style="text-align: center;">{{ $t('All.一部补标识卡') }}</h3>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="auto"
    label-position="right">
      <el-form-item :label="$t('All.工单号')" prop="Wadoco">
        <el-input v-model="formData.Wadoco" :placeholder="$t('All.请输入工单号')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.生产日期')" prop="Date">
        <el-date-picker v-model="formData.Date" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
          :style="{width: '100%'}" :placeholder="$t('All.请选择生产日期')" clearable></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('All.料号')">
        <el-input v-model="formData.ItemNo" :placeholder="$t('All.请输入料号')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.品名')">
        <el-input v-model="formData.Product_name" :placeholder="$t('All.请输入品名')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.规格')">
        <el-input v-model="formData.Gg" :placeholder="$t('All.请输入规格')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.客户')">
        <el-input v-model="formData.CustomerCode" :placeholder="$t('All.请输入客户')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.机台')">
        <el-input v-model="formData.Machine" :placeholder="$t('All.请输入机台')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.班别')">
        <el-input v-model="formData.Banbie" :placeholder="$t('All.请输入班别')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.Lot号')" prop="Lot">
        <el-input v-model="formData.Lot" :placeholder="$t('All.请输入Lot号')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.操作员')">
        <el-input v-model="formData.Username" :placeholder="$t('All.请输入操作员')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item :label="$t('All.数量')">
        <el-input v-model="formData.Zong" :placeholder="$t('All.请输入数量')" clearable :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item
        v-for="(domain, index) in Boxlist"
        :label="$t('All.数量')"
        :key="index"
      >
        <el-input v-model="domain.Boxquantity" style="width:100px;" onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = value.slice(0, value.indexOf('.') + 5); }" autocomplete="off"></el-input>
        <span style="padding: 10px;">{{$t('All.张数')}}:</span><el-input onkeyup="value=value.replace(/[^\d^\.]/g,'')" oninput="if (value.trim() === '') { value = null; } if (isNaN(value)) { value = null; } if (value.indexOf('.') > 0) { value = null; }" v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
      </el-form-item>
      <el-form-item :label="$t('All.打印机')" v-if="formData.State === 0">
          <el-select :style="{width: '100%'}" v-model="Printname" value-key="Id" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item size="large" style="text-align: center;" v-if="formData.State === 0">
        <el-button type="primary" @click="submitForm">{{ $t('All.提交') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    var geigd = (rule, value, callback) => {
      this.GetERP(value)
      callback()
    }
    return {
      formData: {
        Date: '',
        ItemNo: '',
        Tsmpnumber: '',
        Number: '',
        Dnumber: '',
        Connector1: '',
        Connector2: '',
        Connector3: '',
        Connector4: '',
        Connector5: '',
        Connector6: '',
        SjZong: 0,
        Zong: 0,
        Zong1: '',
        Zong2: '',
        Username: this.$store.state.Login.Username,
        User: this.$store.state.Login.User,
        Username1: '',
        User1: '',
        CustomerCode: '',
        Machine: '',
        Banbie: '',
        Name: '',
        Factory: this.$store.state.Login.Factory,
        Wadoco: '',
        State: 0,
        YTsmpnumber: '',
        BTsmpnumber: '',
        Bnumber: '',
        Beizhu: '',
        Axis: '',
        Order_no: '',
        Product_name: '',
        Gg: '',
        Type: 3,
        Lot: ''
      },
      rules: {
        Gg: [{
          required: true,
          message: this.$t('All.请输入规格'),
          trigger: 'blur'
        }],
        Item_no: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Date: [{
          required: true,
          message: this.$t('All.请选择生产日期'),
          trigger: 'change'
        }],
        Lot: [{
          required: true,
          message: this.$t('All.请输入Lot号'),
          trigger: 'blur'
        }],
        Cs: [{
          required: true,
          message: this.$t('All.请输入厂商'),
          trigger: 'blur'
        }],
        Wadoco: [{
          required: true,
          message: this.$t('All.请输入工单号'),
          trigger: 'blur'
        }, {
          required: true,
          validator: geigd,
          trigger: 'blur'
        }],
        Bb: [{
          required: true,
          message: this.$t('All.请输入班别'),
          trigger: 'blur'
        }],
        Ys: [{
          required: true,
          message: this.$t('All.请输入颜色'),
          trigger: 'blur'
        }],
        Yd: [{
          required: true,
          message: this.$t('All.请输入硬度'),
          trigger: 'blur'
        }],
        Pvc: [{
          required: true,
          message: this.$t('All.请输入') + 'PVC',
          trigger: 'blur'
        }],
        Zl: [{
          required: true,
          message: this.$t('All.请输入重量'),
          trigger: 'blur'
        }],
        Dw: [{
          required: true,
          message: this.$t('All.请输入单位'),
          trigger: 'blur'
        }]
      },
      Printname: '',
      Boxlist: [
        {
          Box: 1,
          Boxquantity: 1
        }
      ]
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    addDomainbut () {
      var zong = 0
      this.Boxlist.forEach(element => {
        zong = this.$PublicJs.add(zong, this.$PublicJs.mul(element.Box, element.Boxquantity))
      })
      var shengyu = this.$PublicJs.sub(this.formData.Zong, zong)
      if (shengyu <= 0) return this.$message.error(this.$t('All.数量已不足'))
      this.Boxlist.push({
        Box: 1,
        Boxquantity: shengyu
      })
    },
    removeDomainbut (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          if (this.Boxlist.length === 1) {
            return this.$message.error(this.$t('All.最少保留一列'))
          }
          var index = this.Boxlist.indexOf(item)
          if (index !== -1) {
            this.Boxlist.splice(index, 1)
          }
        })
        .catch(() => {
        })
    },
    submitForm () {
      this.formData.SjZong = this.formData.Zong
      const loadingInstance = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        if (this.Printname.length < 1) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.请选择打印机后在进行打印'))
        }
        this.$refs.elForm.validate(async valid => {
          if (!valid) {
            loadingInstance.close()
            return this.$message.error(this.$t('All.数据填写不完整'))
          }
          var isbool = 0
          this.Boxlist.forEach(element => {
            isbool += parseInt(element.Box)
          })
          if (isbool > 10) {
            loadingInstance.close()
            this.$confirm(`${this.$t('All.确认要打印')}${isbool}${this.$t('All.张')}？`, this.$t('All.提示'), {
              confirmButtonText: this.$t('All.确认'),
              cancelButtonText: this.$t('All.取消'),
              type: 'warning'
            })
              .then(async () => {
                const loadingInstance1 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
                const postdata = {
                  Boxlist: this.Boxlist,
                  Xbcsjk: this.formData,
                  PrinterData: this.Printname,
                  UserData: this.$store.state.Login
                }
                const { data: res } = await this.$http.post('/api/Semifinished/BYBSeleUserData', postdata)
                if (res.status !== 200) {
                  loadingInstance1.close()
                  return this.$message.error(res.msg)
                }
                loadingInstance1.close()
                this.$message.success(res.msg)
                this.$refs.elForm.resetFields()
              })
              .catch(() => {
              })
            return
          }
          const loadingInstance2 = this.$loading({ text: this.$t('All.打印中'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
          const postdata = {
            Boxlist: this.Boxlist,
            Xbcsjk: this.formData,
            PrinterData: this.Printname,
            UserData: this.$store.state.Login
          }
          const { data: res } = await this.$http.post('/api/Semifinished/BYBSeleUserData', postdata)
          if (res.status !== 200) {
            loadingInstance2.close()
            return this.$message.error(res.msg)
          }
          loadingInstance2.close()
          this.$message.success(res.msg)
          this.$refs.elForm.resetFields()
        })
      } catch (error) {
        loadingInstance.close()
      }
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async GetERP (value) {
      const { data: res } = await this.$http.get(`api/Semifinished/GetERP?Gongdan=${value}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData.Gg = res.response.Imdsc2
      this.formData.Product_name = res.response.Imdsc1
      this.formData.ItemNo = res.response.Walitm
      this.formData.Zong = res.response.Wauorg
      this.$message.success(this.$t('All.系统检测已帮申请过的信息自动填写'))
    }
  }
}

</script>
<style>
</style>
